<template>
    <div class="picture-up">
      <b-card>
        <b-form-group label="营业执照图片" label-for="h-imgLicense">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>
      <b-card>
        <b-form-group label="法人身份证正" label-for="h-imgIDCardA1">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="法人身份证反" label-for="h-imgIDCardA2">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="银行卡所有人身份证正" label-for="h-imgIDCardB1">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="银行卡所有人身份证反" label-for="h-imgIDCardB2">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="银行卡" label-for="h-imgBankCard">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="商店门头照" label-for="h-imgStoreFacade">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="协议" label-for="h-imgAgreement">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>

      <b-card>
        <b-form-group label="授权书" label-for="h-imgAuthorize">
          <b-form-file
            v-model="file"
            placeholder="选择或拖拽一张照片到此处"
            drop-placeholder="拖拽到此......"
            browse-text="浏览"
          />
        </b-form-group>
      </b-card>
    </div>
</template>

<script>
import { BCard, BCardText, BFormFile, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BFormFile,
    BFormGroup,
  },
  data() {
    return {
      file: null,
      pictureAll: {
        imgLicense: "", //营业执照图片
        imgIDCardA1: "", //法人身份证正
        imgIDCardA2: "", //法人身份证反
        imgIDCardB1: "", //银行卡所有人身份证正
        imgIDCardB2: "", //银行卡所有人身份证反
        imgBankCard: "", //银行卡
        imgStoreFacade: "", //商店门头照
        imgAgreement: "", //协议
        imgAuthorize: "", //授权书
      },
    };
  },
};
</script>

<style lang="scss">
.picture-up {
  display: flex;
}
</style>
