<template>
  <div>
    <b-row>
      <b-col cols="12">
          <PictureUpload></PictureUpload>
      </b-col>
      <!-- <b-col cols="6">
        <b-card>
          <b-card-title>一级标题</b-card-title>
          <b-card-sub-title>二级标题</b-card-sub-title>
          <div style="height: 1rem"></div>
          <MyTable :fields="fields" :isShowHeader="false"></MyTable>
        </b-card>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <ApplicationForm></ApplicationForm>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";

import PictureUpload from "./childComps/PictureUpload.vue";
import ApplicationForm from "./childComps/ApplicationForm.vue";
import MyTable from "@/components/MyTable.vue";

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BCardTitle,
    BCardSubTitle,

    PictureUpload,
    ApplicationForm,
    MyTable,
  },
  data() {
    return {
      fields: [
        {
          key: "th1",
          label: "表头1",
        },
        {
          key: "th2",
          label: "表头2",
        },
        {
          key: "th3",
          label: "表头3",
        },
        {
          key: "th4",
          label: "表头4",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>