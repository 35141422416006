<template>
  <b-form @submit.prevent>
    <!-- label-cols-md 表单字段占位符,不填则在输入框上方 -->
    <b-card-title>商户信息</b-card-title>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="商户名称:"
          label-for="h-first-name"
          label-cols-md="2"
        >
          <b-form-input id="h-first-name" placeholder="请输入商户名称" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="商户简称:" label-for="h-email" label-cols-md="2">
          <b-form-input
            id="h-email"
            type="email"
            placeholder="请输入商户简介"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="电话:" label-for="h-password" label-cols-md="2">
          <b-form-input
            id="h-password"
            type="number"
            placeholder="请输入电话"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="省份:" label-for="h-number" label-cols-md="2">
          <v-select
            v-model="form.a"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="provinceOption"
            placeholder="请选择省份"
            @input="inputProvince"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="市:" label-for="h-password" label-cols-md="2">
          <v-select
            v-model="form.b"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="cityOption"
            placeholder="请选择市"
            @input="inputCity"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="区/县:" label-for="h-password" label-cols-md="2">
          <v-select
            v-model="form.c"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="areaOption"
            :reduce="(option) => option.name"
            placeholder="请选择区/县"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="详细地址:"
          label-for="h-password"
          label-cols-md="2"
        >
          <b-form-input id="h-password" placeholder="请输入详细地址" />
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group
          label="企业证件号:"
          label-for="h-password"
          label-cols-md="3"
        >
          <b-form-input id="h-password" placeholder="请输入企业证件号" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="企业证件开始日:"
          label-for="h-password"
          label-cols-md="3"
        >
          <flat-pickr
            v-model="dateDefault"
            class="form-control"
            :config="dataConfig"
            placeholder="请选择企业证件开始日"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="企业证件到期日:"
          label-for="h-password"
          label-cols-md="3"
        >
          <flat-pickr
            v-model="dateDefault"
            class="form-control"
            :config="dataConfig"
            placeholder="请选择企业证件到期日"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <!-- 法人信息 -->
      <b-col>
        <b-card-title>法人信息</b-card-title>
        <b-row>
          <b-col>
            <b-form-group
              label="负责人名称:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入负责人名称" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="负责人电话:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入负责人电话" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="法人/负责人证件号码:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input
                id="h-first-name"
                placeholder="请输入法人/负责人证件号码"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="法人/负责人证件开始日:"
              label-for="h-password"
              label-cols-md="6"
            >
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="dataConfig"
                placeholder="请选择开始日"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="法人/负责人证件到期日:"
              label-for="h-password"
              label-cols-md="6"
            >
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="dataConfig"
                placeholder="请选择到期日"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- 结算信息 -->
      <b-col>
        <b-card-title>结算信息</b-card-title>
        <b-row>
          <b-col>
            <b-form-group
              label="银行名称:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入银行名称" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="银行账号:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入银行账号" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="开户名称:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入开户名称" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="开户支行:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入开户支行" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="证件号码:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入证件号码" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="手机号码:"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-input id="h-first-name" placeholder="请输入手机号码" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- submit and reset -->
    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1"
      >
        提交
      </b-button>
      <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button> -->
    </b-col>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCardTitle,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Mandarin } from "flatpickr/dist/l10n/zh.js"; //引入普通话语言包

import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCardTitle,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        a: "",
        c: "",
        b: "",
      },

      dateDefault: "",
      option: [
        { title: "达达", value: "dada" },
        { title: "闪送", value: "shansong" },
        { title: "UU", value: "uu" },
        { title: "顺丰同城", value: "shunfeng" },
      ],
      provinceOption: [],
      cityData: null,
      cityOption: [],
      areaData: null,
      areaOption: [],
      dataConfig: {
        locale: Mandarin,
        altFormat: "Y-m-d",
        dateFormat: "Y-m-d",
      },
    };
  },
  created() {
    // this.getSSQ();
  },
  methods: {
    getSSQ() {
      axios
        .get("/province.json")
        .then((res) => {
          console.log(res, "省");
          this.provinceOption = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/city.json")
        .then((res) => {
          console.log(res, "市");
          this.cityData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/area.json")
        .then((res) => {
          console.log(res, "区");
          this.areaData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //过滤省市区
    inputProvince() {
    //   this.cityOption = this.cityData[this.form.a.id]
    },
    inputCity() {
    //   this.areaOption = this.areaData[this.form.b.id]

    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>